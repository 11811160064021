const IDS_TO_NAMES: Record<string, string> = {
  chihuahua: "Chihuahua",
  "siberian-husky": "Siberian Husky",
  "yorkshire-terrier": "Yorkshire Terrier",
  "french-bulldog": "French Bulldog",
  "german-shepherd": "German Shepherd",
  "labrador-retriever": "Labrador Retriever",
  dachshund: "Dachshund",
  "golden-retriever": "Golden Retriever",
  beagle: "Beagle",
  bulldog: "Bulldog",
  "miniature-poodle": "Miniature Poodle",
  "australian-shepherd": "Australian Shepherd",
  "great-dane": "Great Dane",
  "german-shorthaired-pointer": "German Shorthaired Pointer",
  "shetland-sheepdog": "Shetland Sheepdog",
  "standard-poodle": "Standard Poodle",
  "shih-tzu": "Shih Tzu",
  rottweiler: "Rottweiler",
  "staffordshire-bull-terrier": "Staffordshire Bull Terrier",
  "belgian-malinois": "Belgian Malinois",
  samoyed: "Samoyed",
  "pembroke-welsh-corgi": "Pembroke Welsh Corgi",
  newfoundland: "Newfoundland",
  "doberman-pinscher": "Doberman Pinscher",
  "bichon-frise": "Bichon Frise",
  maltese: "Maltese",
  "miniature-schnauzer": "Miniature Schnauzer",
  "west-highland-white-terrier": "West Highland White Terrier",
  "cocker-spaniel": "Cocker Spaniel",
  pug: "Pug",
  "boston-terrier": "Boston Terrier",
  "bernese-mountain-dog": "Bernese Mountain Dog",
  pomeranian: "Pomeranian",
  weimaraner: "Weimaraner",
  "english-springer-spaniel": "English Springer Spaniel",
  dalmatian: "Dalmatian",
  "cavalier-king-charles-spaniel": "King Charles Spaniel",
  "border-collie": "Border Collie",
  boxer: "Boxer",
  "standard-schnauzer": "Standard Schnauzer",
  akita: "Akita",
  "russell-terrier": "Russell Terrier",
  "dogue-de-bordeaux": "Dogue de Bordeaux",
  "miniature-pinscher": "Miniature Pinscher",
  "nova-scotia-duck-tolling-retriever": "Toller",
  bullmastiff: "Bullmastiff",
  "giant-schnauzer": "Giant Schnauzer",
  "airedale-terrier": "Airedale Terrier",
  "english-cocker-spaniel": "English Cocker Spaniel",
  mastiff: "Mastiff",
  "english-setter": "English Setter",
  "shiba-inu": "Shiba Inu",
  "toy-poodle": "Toy Poodle",
  "american-eskimo": "American Eskimo",
  brittany: "Brittany",
  "cardigan-welsh-corgi": "Cardigan Welsh Corgi",
  "belgian-tervuren": "Belgian Tervuren",
  bloodhound: "Bloodhound",
  "anatolian-shepherd": "Anatolian Shepherd",
  "german-wirehaired-pointer": "German Wirehaired Pointer",
  "belgian-sheepdog": "Belgian Sheepdog",
  "gordon-setter": "Gordon Setter",
  "italian-greyhound": "Italian Greyhound",
  "border-terrier": "Border Terrier",
  "flat-coated-retriever": "Flat-Coated Retriever",
  "chinese-crested": "Chinese Crested",
  "portuguese-water-dog": "Portuguese Water Dog",
  greyhound: "Greyhound",
  "old-english-sheepdog": "Old English Sheepdog",
  "st-bernard": "St. Bernard",
  papillon: "Papillon",
  whippet: "Whippet",
  "american-bulldog": "American Bulldog",
  "rhodesian-ridgeback": "Rhodesian Ridgeback",
  "american-staffordshire-terrier": "AmStaff",
  "scottish-terrier": "Scottish Terrier",
  "wire-fox-terrier": "Wire Fox Terrier",
  vizsla: "Vizsla",
  pekingese: "Pekingese",
  "great-pyrenees": "Great Pyrenees",
  "alaskan-malamute": "Alaskan Malamute",
  havanese: "Havanese",
  "chinese-shar-pei": "Chinese Shar-Pei",
  "afghan-hound": "Afghan Hound",
  "basset-hound": "Basset Hound",
  "australian-cattle-dog": "Australian Cattle Dog",
  "soft-coated-wheaten-terrier": "Soft Coated Wheaten Terrier",
  "lhasa-apso": "Lhasa Apso",
  "chesapeake-bay-retriever": "Chesapeake Bay Retriever",
  pointer: "Pointer",
  collie: "Collie",
  "bull-terrier": "Bull Terrier",
  "irish-wolfhound": "Irish Wolfhound",
  "irish-setter": "Irish Setter",
  "cane-corso": "Cane Corso",
  "chow-chow": "Chow Chow",
  "cairn-terrier": "Cairn Terrier",
  basenji: "Basenji",
  "irish-water-spaniel": "Irish Water Spaniel",
  "polish-lowland-sheepdog": "Polish Lowland Sheepdog",
  "norwich-terrier": "Norwich Terrier",
  "german-pinscher": "German Pinscher",
  "ibizan-hound": "Ibizan Hound",
  "presa-canario": "Presa Canario",
  saluki: "Saluki",
  boerboel: "Boerboel",
  "bearded-collie": "Bearded Collie",
  puli: "Puli",
  "lakeland-terrier": "Lakeland Terrier",
  "smooth-fox-terrier": "Smooth Fox Terrier",
  "mexican-hairless-dog": "Mexican hairless dog",
  "welsh-springer-spaniel": "Welsh Springer Spaniel",
  borzoi: "Borzoi",
  "greater-swiss-mountain-dog": "Greater Swiss Mountain Dog",
  briard: "Briard",
  "dutch-shepherd": "Dutch Shepherd",
  "wirehaired-pointing-griffon": "Wirehaired Pointing Griffon",
  "petit-basset-griffon-vendéen": "Petit Basset Griffon",
  "lagotto-romagnolo": "Lagotto Romagnolo",
  "treeing-walker-coonhound": "Treeing Walker Coonhound",
  "tibetan-terrier": "Tibetan Terrier",
  "irish-terrier": "Irish Terrier",
  "standard-manchester-terrier": "Standard Manchester Terrier",
  "finnish-spitz": "Finnish Spitz",
  "american-foxhound": "American Foxhound",
  "neapolitan-mastiff": "Neapolitan Mastiff",
  "bedlington-terrier": "Bedlington Terrier",
  "parson-russell-terrier": "Parson Russell Terrier",
  "spinone-italiano": "Spinone Italiano",
  "miniature-american-shepherd": "Miniature American Shepherd",
  "finnish-lapphund": "Finnish Lapphund",
  "norwegian-elkhound": "Norwegian Elkhound",
  "toy-manchester-terrier": "Toy Manchester Terrier",
  "silky-terrier": "Silky Terrier",
  keeshond: "Keeshond",
  "welsh-terrier": "Welsh Terrier",
  leonberger: "Leonberger",
  affenpinscher: "Affenpinscher",
  "coton-de-tulear": "Coton de Tulear",
  beauceron: "Beauceron",
  "irish-red-and-white-setter": "Irish Red and White Setter",
  "black-and-tan-coonhound": "Black and Tan Coonhound",
  "catahoula-leopard-dog": "Catahoula Leopard Dog",
  "miniature-bull-terrier": "Miniature Bull Terrier",
  "bouvier-des-flandres": "Bouvier des Flandres",
  "spanish-water-dog": "Spanish Water Dog",
  kuvasz: "Kuvasz",
  "australian-terrier": "Australian Terrier",
  "japanese-chin": "Japanese Chin",
  "redbone-coonhound": "Redbone Coonhound",
  "american-hairless-terrier": "American Hairless Terrier",
  "australian-kelpie": "Australian Kelpie",
  "dogo-argentino": "Dogo Argentino",
  "scottish-deerhound": "Scottish Deerhound",
  "tibetan-spaniel": "Tibetan Spaniel",
  "toy-fox-terrier": "Toy Fox Terrier",
  "brussels-griffon": "Brussels Griffon",
  "rat-terrier": "Rat Terrier",
  schipperke: "Schipperke",
  "curly-coated-retriever": "Curly-Coated Retriever",
  "kerry-blue-terrier": "Kerry Blue Terrier",
  "norfolk-terrier": "Norfolk Terrier",
  "pyrenean-mastiff": "Pyrenean Mastiff",
  "english-toy-spaniel": "English Toy Spaniel",
  "dandie-dinmont-terrier": "Dandie Dinmont Terrier",
  "clumber-spaniel": "Clumber Spaniel",
  "volpino-italiano": "Volpino Italiano",
  komondor: "Komondor",
  "american-leopard-hound": "American Leopard Hound",
  "lapponian-herder": "Lapponian Herder",
  "lancashire-heeler": "Lancashire Heeler",
  "plott-hound": "Plott Hound",
  "german-spitz": "German Spitz",
  "alaskan-klee-kai": "Alaskan Klee Kai",
  "bluetick-coonhound": "Bluetick Coonhound",
  "portuguese-podengo-pequeno": "Portuguese Podengo Pequeno",
  "australian-stumpy-tail-cattle-dog": "Stumpy",
  "skye-terrier": "Skye Terrier",
  "teddy-roosevelt-terrier": "Teddy Roosevelt Terrier",
  tosa: "Tosa",
  azawakh: "Azawakh",
};

const getDogName = (id: string) => {
  return IDS_TO_NAMES[id] || id;
};

export default getDogName;
